export enum AppRoutes {

  HOME = '/home',
  UPCOMING = '/upcoming',
  BROWSE = '/browse',
  COMING = '/coming',
  BLOG = '/blog',
  POST = '/post',
  SUBSCRIBE = '/subscribe',
  MEDIA_LANDING = '/media/',
  APARADHBODH = '/aparadhbodh/',
  SEASON_VIDEO_LANDING = '/seasons-description/',
  
  LOGIN = '/login',
  REGISTER = '/register',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset',

  PROFILE_SETTINGS = '/profile-settings',
  SUPPORT = '/support',
  FAQ = '/faq',

  ABOUT_US = '/about-us',
  CONTACT_US = '/contact-us',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  PRIVACY_POLICY = '/privacy-policy',
  REFUND_POLICY = '/refund-policy',

  MAINTENANCE = '/maintenance',

  SUBMIT_CONTENT = '/submit-content',
  Cookie_Policy = '/cookie-policy'
}
