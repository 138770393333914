import { Injectable } from '@angular/core';
import { CurrentUser } from '../models/user/current-user';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Order } from '../models/payment/order';
import { PaypalOrder } from '../models/payment/paypal-order';
import { PageableResponse } from '../models/response-types/pageable-response';
import { SubscriptionPackage } from '../models/subscription/subscription-package';
import { Urls } from '../shared/urls';

import { Location } from '../models/subscription/location';
import { SubscriptionTypes } from '../models/subscription/subscriptionTypes';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  ipAddress: any = '';
  userId: any;
  constructor(private httpClient: HttpClient, private urls: Urls) {
  }

  getSubscriptionTypes(): Observable<SubscriptionTypes[]> {
    this.ipAddress = sessionStorage.getItem('ip');
    const params = new HttpParams()
      .set('ip', this.ipAddress);

    return this.httpClient
      .get<SubscriptionTypes[]>(this.urls.fetchForCountryPaymentTypeURL, { params });
  }

  // GET: - Fetch for Subscription List
  // ----------------------------------------------------------------
  fetchForSubscriptionList(): Observable<PageableResponse<SubscriptionPackage>> {
    
    //const ipAddress = sessionStorage.getItem('ip');
    const ipAddress = localStorage.getItem('ipAddress');
    //this.userId = sessionStorage.getItem('user_id');
    this.userId = localStorage.getItem('user_id');
    if(!this.userId){
      //this.userId = sessionStorage.getItem('regID');
      this.userId = localStorage.getItem('regID');
    }
    const params: HttpParams = new HttpParams()
    .set('ip', ipAddress)
    .set('id', this.userId);

    return this.httpClient
      .post<PageableResponse<SubscriptionPackage>>(this.urls.fetchForSubscriptionListURL, null, { params });
  }

  // POST: - Prepare Subscription Order Id for PayPal
  // ----------------------------------------------------------------
  prepareSubscriptionOrderForPaypal(subscriptionPackageId: number): Observable<PaypalOrder> {


    return this.httpClient
      .post<PaypalOrder>(`${this.urls.prepareSubscriptionOrderForPaypalURL}${subscriptionPackageId}`, null );
  }

  // GET: - Prepare Subscription Order Id for PayTM
  // ----------------------------------------------------------------
  prepareSubscriptionOrderForPayTM(subscriptionPackageId: any, selectedPlanPrice: any, usermobile: any): Observable<Order> {
    
    //this.userId = sessionStorage.getItem('user_id');
    this.userId = localStorage.getItem('user_id');
    if(!this.userId){
      //this.userId = sessionStorage.getItem('regID');
      this.userId = localStorage.getItem('regID');
    }
    const params: HttpParams = new HttpParams()
      .set('id', this.userId)
      .set('subscription_id', subscriptionPackageId)
      .set('amount', selectedPlanPrice)
      .set('mobile', usermobile);

    return this.httpClient
      .post<Order>(`${this.urls.prepareSubscriptionOrderForPayTMURL}`, { responseType: "text/html" }, { params });
  }

  prepareSubscriptionOrderForAgreepay(subscriptionPackageId: any, selectedPlanPrice: any, usermobile: any, name:any, email:any): Observable<Order> {
    //this.userId = sessionStorage.getItem('user_id');
    this.userId = localStorage.getItem('user_id');
    console.log('User_ID',this.userId);
    console.log('Sub_id',subscriptionPackageId);
    
    if(!this.userId){
      //this.userId = sessionStorage.getItem('regID');
      this.userId = localStorage.getItem('regID');
    }
    const params: HttpParams = new HttpParams()
      .set('id', this.userId)
      .set('subscription_id', subscriptionPackageId)
      .set('amount', selectedPlanPrice)
      .set('mobile', usermobile)
      .set('name', name)
      .set('email', email);

    return this.httpClient
     // .post<Order>(`${this.urls.prepareSubscriptionOrderForAgreepayURL}`, null, { params });
     .post<Order>(`${this.urls.prepareSubscriptionOrderForAgreepayURL}`, {responseType: 'text/html'},  { params });
  }
  // GET: - Prepare Subscription Order Id for AirPay
  // ----------------------------------------------------------------
  prepareSubscriptionOrderForAirpay(subscriptionPackageId: any): Observable<Order> {
    //this.userId = sessionStorage.getItem('user_id');
    this.userId = localStorage.getItem('user_id');
    if(!this.userId){
      //this.userId = sessionStorage.getItem('regID');
      this.userId = localStorage.getItem('regID');
    }
    const params: HttpParams = new HttpParams()
      .set('id', this.userId)
      .set('subscription_id', subscriptionPackageId);

    return this.httpClient
      .post<Order>(`${this.urls.prepareSubscriptionOrderForAirpayURL}`, { params });
    // .post<Order>(`${this.urls.prepareSubscriptionOrderForAirpayURL}`, {responseType: 'text/html'}, { params });
  }

  // POST: - Prepare Order for Stripe
  // ----------------------------------------------------------------
  prepareSubscriptionOrderForStripe(subscriptionPackageId: number): Observable<Order> {

    const params: HttpParams = new HttpParams()
      .set('stripeClient', 'WEB');

    return this.httpClient
      .post<Order>(`${this.urls.prepareSubscriptionOrderForStripeURL}/${subscriptionPackageId}`, null, { params });
  }

  // POST: - Paypal Checkout
  // ----------------------------------------------------------------
  paypalCheckout(nonce: string, orderId: string, paymentID: string): Observable<CurrentUser> {

    const params = new HttpParams()
      .set('paymentMethodNonce', nonce)
      .set('consumerOrderId', orderId)
      .set('paymentID', paymentID);

    return this.httpClient
      .post<CurrentUser>(this.urls.paypalCheckoutURL, null, { params });
  }

  //Age verify
  userAgeVerify(userage: any) {
    this.ipAddress = sessionStorage.getItem('ip');
    //this.userId = sessionStorage.getItem('user_id');
    this.userId = localStorage.getItem('user_id');
    if(!this.userId){
      //this.userId = sessionStorage.getItem('regID');
      this.userId = localStorage.getItem('regID');
    }
    const params: HttpParams = new HttpParams()
      .set('id', this.userId)
      .set('age', userage)
      .set('ip', this.ipAddress);

    return this.httpClient
      .post(this.urls.ageVerifyUrl, null, { params });
  }

  getUsersLocation(): Observable<Location[]> {
    console.log(this.ipAddress);
    if(localStorage.getItem('regID') != undefined)
    {
      var userId = localStorage.getItem('regID');
    }
    else
    {
      var userId = localStorage.getItem('user_id');

    }
    this.ipAddress = sessionStorage.getItem('ip');
    const params = new HttpParams()
      .set('ip', this.ipAddress)
      .set('id', userId);

    return this.httpClient
      .post<Location[]>(this.urls.fetchForCountryStateCityURL, null, { params });
  }

}
