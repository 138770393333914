import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AppRoutes } from '../../models/core/enums/app-routes.enum';
import { MediaService } from '../../services/media.service';
import { MinifiedMedia } from '../../models/home-screen-media/minified-media';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { Subscription } from 'rxjs';
import { Urls } from '../../shared/urls';
import { finalize } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UpcomingMediaService } from 'src/app/services/upcoming-media.service';

@Component({
  selector: 'app-coming',
  templateUrl: './coming.component.html',
  styleUrls: ['./coming.component.scss']
})
export class ComingComponent implements OnInit, OnDestroy {

  downloadFileURL = this.urls.downloadFileURL;
  keyword = '';
  mediaList: MinifiedMedia[] = [];
  subscriptions: Subscription[] = [];
  pageIndex = 0;
  aRecord:any;
  upcomingVideos: any;
  finalData:any;
dialogData: any;

  constructor( private analytics: AngularFireAnalytics,
               private mediaService: MediaService,
               private router: Router,
               private sharedService: SharedService,
               private urls: Urls,
               private httpClient: HttpClient,
               private upcomingMediaService: UpcomingMediaService ) {

    this.searchForMediaListByTitle();

  }


  url="./assets/img/upcoming/upcoming.jpg";
  icon="./assets/img/upcoming/icon.png";
  icon1="./assets/img/upcoming/icon1.png";
  icon2="./assets/img/upcoming/icon2.png";
  text ="Ravi is settled family man. One day a letter from his past gets delivered to him after 25 yrs and shakes entire foundation of his current reality. More he tries to bury his past more he gets entangled into the web of moral ethical and family values forcing him to take a decision which is going to destroy everything he has earned.";

  ngOnInit(): void {

    // this.upcomingMediaService.fetchForUpcomingMediaList().subscribe((res: any) => {
    // console.log('respose_api',res)
    //   this.upcomingVideos.push(...res.data);
    //   for (const iterator of this.upcomingVideos) {
    //       console.warn(iterator.trailer_video);
    //   }
    // });


  }

  // Search for Media List by Title
  // ----------------------------------------------------------------
  searchForMediaListByTitle(paging: boolean = false): void {

    this.sharedService.updateSpinnerState(true);

    if (!paging) {
      this.pageIndex = 0;
    }
    this.httpClient.get("https://api64.ipify.org/?format=json").subscribe((res:any)=>{
    const subscription = this.mediaService.upcommingVideoService(this.keyword, this.pageIndex, res.ip)
      .pipe(finalize(() => this.sharedService.updateSpinnerState(false)))
      .subscribe(mediaList => {
        console.log("Medialist",mediaList);
        
        this.upcomingVideos = mediaList;
        this.finalData = this.upcomingVideos.data
          if ( paging ) {

            if (mediaList.content.length > 0) {
              this.mediaList = this.mediaList.concat( mediaList.content );
            } else { 
              this.pageIndex -= 1;
            }
          } else {
            this.mediaList = mediaList.content;
          }
        },
        error => console.error(error));

    this.subscriptions.push(subscription);
      })
  }


  
 

  // Start Search on enter
  // ----------------------------------------------------------------
  startSearch($event: KeyboardEvent, value: string): void {

    if ( $event.key === 'Enter' || value === '' ) {

      this.keyword = value.trim();
      this.searchForMediaListByTitle();
    } 
  }

  // Clear Search Input
  // ----------------------------------------------------------------
  clearInput(): void {

    this.keyword = '';
    this.searchForMediaListByTitle();
  }

  // Navigate to Media Landing
  // ----------------------------------------------------------------
  navigateToMediaLanding(titleYearSlug: string, id: string = null, name: string = null, contentType: string = null, slider_type:any, parent_media: any): void {

    this.router.navigate([ `${ AppRoutes.APARADHBODH }${ titleYearSlug }` ]).then();

    if ( id !== null && name !== null && contentType !== null ) {

      this.analytics.logEvent('select_content', {
        content_id: id,
        content_type: contentType, 
        items: [ { name } ]
      } ).then();
    }
  }

  ngOnDestroy(): void {

    SharedService.unsubscribe(this.subscriptions);
  }




}
