import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from './models/user/user';
import { AuthServiceProvider } from './models/user/enums/auth-service-provider.enum';
declare var FB: any;

@Injectable({
  providedIn: 'root'
})
export class FacebookLoginService {
  triggerMethod = new Subject<any>();


  constructor() {
    FB.init({
      appId: "596695202490607",
      status: true,
      xfbml: true,
      version: "v2.7", // or v2.6, v2.5, v2.4, v2.3
    });
    console.log("FB" + FB);
  }

  public signIn() {
    FB.login(this.handleCredentialResponse.bind(this));
  }

  public handleCredentialResponse(val: any) {
    FB.api('/me', (val: any) => {
      console.log(val + this);
      let socialUser = new User()
        .withAuthServiceProvider(AuthServiceProvider.GOOGLE)
        .withLogineProvider(AuthServiceProvider.GOOGLE)
        .withFullName(val.name)
        .withEmail(val.email ? val.email : '')
        .withProfileImageId(val.picture ? val.picture : '')
        .withIdToken(val.id ? val.id : '')
        .withPassword(val.id ? val.id : '');
      sessionStorage.setItem("socialData", JSON.stringify(socialUser));
      localStorage.setItem("socialData", JSON.stringify(socialUser));
      this.serviceMethod(socialUser);
    })
  }



  public logout() {
    FB.logout((res: any) => {
      console.log(res)
    });
  }

  public serviceMethod(myCustomParam: any): void {
    this.triggerMethod.next(myCustomParam);
  }
  public fun() {

  }
}
