import { Injectable } from '@angular/core';
import { AllowedToWatchParams } from '../models/media/allowed-to-watch-params';
import { AllowedToWatchResponse } from '../models/response-types/allowed-to-watch-response';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Media } from '../models/media/media';
import { MinifiedMedia } from '../models/home-screen-media/minified-media';
import { Observable } from 'rxjs';
import { PageableResponse } from '../models/response-types/pageable-response';
import { SharedService } from '../shared/shared.service';
import { Urls } from '../shared/urls';
import { SuccessResponse } from '../models/response-types/success-response';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(private httpClient: HttpClient, private urls: Urls) {
  }

  // Fetch for Media by Title Year Slug
  // ----------------------------------------------------------------
  // fetchForMediaByTitleYearSlug(titleYearSlug: string): Observable<Media> {

  //   const params: HttpParams = new HttpParams()
  //     .set('titleYearSlug', titleYearSlug);

  //   return this.httpClient
  //     .get<Media>(this.urls.fetchForMediaByTitleYearSlugURL, { params });
  // }

  fetchForMediaByTitleYearSlug(titleYearSlug: string, videoId: any, episode: any): Observable<Media> {
    videoId = localStorage.getItem('videoId');
    const params: HttpParams = new HttpParams()
      .set('titleYearSlug', titleYearSlug)
      .set('videoid', videoId)
      .set('episode', episode);

    return this.httpClient
      .get<Media>(this.urls.fetchForMediaByTitleYearSlugURL, { params });
  }

  // upcoming api call
  // upcommingVideoService(keyword: any, page: number, ip:any): Observable<PageableResponse<MinifiedMedia>> {

  //   return this.httpClient
  //     .get<PageableResponse<MinifiedMedia>>( this.urls.upcomingForUpcomingMediaList );
  // }


  upcommingVideoService(keyword: any, page: number, ip:any): Observable<PageableResponse<MinifiedMedia>> {

    const params = new HttpParams()
      .set( 'key', keyword )
      .set( 'ip', ip )
      .set( 'page', page.toString() );

    return this.httpClient
      .post<PageableResponse<MinifiedMedia>>( this.urls.upcomingForUpcomingMediaList, null,{ params } );
  }



  // Search for Media List by Title
  // ----------------------------------------------------------------
  searchForMediaListByTitle(keyword: any, page: number,ip:any): Observable<PageableResponse<MinifiedMedia>> {

    const params = new HttpParams()
      .set('key', keyword)
      .set('ip', ip)
      .set('page', page.toString());

    return this.httpClient
      .post<PageableResponse<MinifiedMedia>>(this.urls.searchForMediaByTitleURL, null, { params });
  }

  // Fetch for Popular Media List
  // ----------------------------------------------------------------
  fetchForPopularMediaList(page: number, size: number = 20): Observable<PageableResponse<MinifiedMedia>> {

    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());

    return this.httpClient
      .get<PageableResponse<MinifiedMedia>>(this.urls.fetchForPopularMediaListURL, { params });
  }

  // Fetch for Media List by Media Category Title
  // ----------------------------------------------------------------
  fetchForMediaListByMediaCategoryTitle(mediaCategoryTitle: string, page: number, size: number = 20): Observable<PageableResponse<MinifiedMedia>> {

    const params = new HttpParams()
      .set('title', mediaCategoryTitle)
      .set('page', page.toString())
      .set('size', size.toString());

    return this.httpClient
      .get<PageableResponse<MinifiedMedia>>(this.urls.fetchForMediaListByMediaCategoryTitleURL, { params });
  }

  // Check Whether Current User is Allowed to watch media
  // ----------------------------------------------------------------
  checkWhetherCurrentUserIsAllowedToWatchMedia(allowedToWatchParams: AllowedToWatchParams): Observable<AllowedToWatchResponse> {

    const params = new HttpParams()
      .set('mediaContentId', allowedToWatchParams.mediaContentId)
      .set('platform', SharedService.fetchForBrowserDetails().browserName.toLowerCase())
      .set('streamUrl', allowedToWatchParams.streamUrl)
      .set('videoId', allowedToWatchParams.videoId)
      .set('token', allowedToWatchParams.token);

    return this.httpClient
      .get<AllowedToWatchResponse>(this.urls.checkWhetherCurrentUserIsAllowedToWatchMediaURL, { params });
  }

  // Send Media Playback Event
  // ----------------------------------------------------------------
  sendMediaPlaybackEvent(payload: any): Observable<SuccessResponse> {

    return this.httpClient
      .post<SuccessResponse>(this.urls.sendMediaPlaybackEventURL, payload);
  }

  // GET: - Fetch For Cookies
  // ----------------------------------------------------------------------------------
  fetchForCookies(): Observable<any> {
    return this.httpClient
      .get<any>(this.urls.getCookiesFromServer);
  }

  // fetchTestVideoUrlForMedia(): Observable<any> {
  //   return this.httpClient
  //     .get<any>(this.urls.getTestVideoUrlForMedia);
  // }


}
