import { AuthServiceProvider } from './enums/auth-service-provider.enum';
import { UserRole } from './enums/user-role.enum';
import { ConsumerSubscription } from './consumer-subscription';

export class User {

  authServiceProvider: AuthServiceProvider;
  contact: string;
  id: string;
  fullName: string;
  email: string;
  password: string;
  profileImageId: string;
  state: string;
  idToken: string;
  primaryRole: UserRole;
  createdAt: string;
  consumerSubscription: ConsumerSubscription;
  enabled: boolean;
  platform: string;
  username: string;
  userID: any;
  device_token = 'my_device_token';
  device_type = 'web';
  login_by:string;
  login_token:string;
  gender :string;
  token_expiry:string;
  isSubscribed:any;
  repassword:any;
  browserVersion:any;
  ip: string;
  refferer:any;

  constructor() {

    this.authServiceProvider = null;
    this.contact = null;
    this.id = null;
    this.fullName = null;
    this.email = null;
    this.password = null;
    this.profileImageId = null;
    this.state = null;
    this.idToken = null;
    this.primaryRole = null;
    this.createdAt = null;
    this.consumerSubscription = null;
    this.enabled = null;
    this.platform = 'WEB';
    this.username = null;
    this.repassword = null;
    this.userID = sessionStorage.getItem('userID');
  }

  withAuthServiceProvider(authServiceProvider: AuthServiceProvider): this {

    this.authServiceProvider = authServiceProvider;
    return this;
  }

  withLogineProvider(authServiceProvider: AuthServiceProvider): this {

    this.login_by = authServiceProvider;
    if(this.login_by == 'GOOGLE')
    {
      this.login_by = 'google'
    }
    else if(this.login_by == 'FACEBOOK')
    {
      this.login_by = 'facebook'
    }
    else
    {
      this.login_by = 'manual'
    }
    return this;
  }
  
  withContact(contact: string): this {

    this.contact = contact;
    return this;
  }

  withFullName(fullName: string): this {

    this.fullName = fullName;
    return this;
  }

  withEmail(email: string): this {

    this.email = email;
    return this;
  }

  withPassword(password: string): this {

    this.password = password;
    return this;
  }

  withProfileImageId(profileImageId: string): this {

    this.profileImageId = profileImageId;
    return this;
  }

  withIdToken(idToken: string): this {

    this.idToken = idToken;
    return this;
  }
}
