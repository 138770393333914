import { Pipe, PipeTransform } from '@angular/core';
import { SubscriptionPackage } from '../models/subscription/subscription-package';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(subscriptionPackage: SubscriptionPackage, ...args: any[]): any {

    let currency;
    let price;

    if(subscriptionPackage.chk_country == 'India')
    {
      currency = subscriptionPackage.currency;
      price = 'INR';
    }
    else
    {
      currency = subscriptionPackage.symbol;
      price = subscriptionPackage.code;
    }

    if ( subscriptionPackage.listedPrice < subscriptionPackage.amount ) {

      return `<p class="deletedPrice">
              <del>
                <small>${ currency }</small> ${ subscriptionPackage.amount }
              </del> </p> 
              <p class="actualPrice">
              <small class="currency">${ currency }</small> ${ subscriptionPackage.listedPrice } </p> `;
    }

    return `<small class="currency">${ currency }</small> ${ subscriptionPackage.listedPrice }`;
  }

}
