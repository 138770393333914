import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '../../models/core/enums/app-routes.enum';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'app-push-permission',
  templateUrl: './push-permission.component.html',
  styleUrls: ['./push-permission.component.scss']
})
export class PushPermissionComponent implements OnInit {
  appRoutes = AppRoutes;

  openLink(){
    this.router.navigate(['./TERMS_AND_CONDITIONS']);
  }
 
  constructor(private router: Router,
    private matDialog: MatDialog
    ) {
  }

  ngOnInit(): void {
  }

  openDialog() {
    this.matDialog.open(PushPermissionComponent, { disableClose: true });
  }
  openNewTab(url) {
    window.open(url, '_blank');
  }
}
