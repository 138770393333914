import { Injectable } from '@angular/core';
import { CurrentUser } from '../models/user/current-user';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { Notyf } from 'notyf';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { positionElements } from '@ng-bootstrap/ng-bootstrap/util/positioning';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  appStorage = sessionStorage;
  private appSpinner: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  private bottomScrolled: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  bottomReached: Observable<boolean> = this.bottomScrolled.asObservable();
  private currentUser: ReplaySubject<CurrentUser> = new ReplaySubject<CurrentUser>();
  private loggedIn: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  notyf = new Notyf({
    types: [
      {
        type: 'warning',
        backgroundColor: '#ebcb41',
        
        icon: {
          className: 'fas fa-exclamation-triangle',
          tagName: 'i'
        },
      }
    ]
    
  });

  notyf_success = new Notyf({
    types: [
      {
        type: 'success',
        backgroundColor: '#ebcb41',
        icon: {
          className: 'fas fa-exclamation-triangle ',
          tagName: 'i'
        }
      }
    ]
  });
  loggedInState: Observable<boolean> = this.loggedIn.asObservable();
  spinnerState: Observable<boolean> = this.appSpinner.asObservable();
  userDetails: Observable<CurrentUser> = this.currentUser.asObservable();

  constructor( private matDialog: MatDialog ) {}

  // Fetch User Browser Details
  //
  // Reference from https://stackoverflow.com/questions/11219582/how-to-detect-my-browser-version-and-operating-system-using-javascript
  // ----------------------------------------------------------------
  static fetchForBrowserDetails(): any {

    const nVer = navigator.appVersion;
    const nAgt = navigator.userAgent;
    let browserName = navigator.appName;
    let fullVersion = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset = null;
    let verOffset = null;
    let ix = null;
    let osName = 'Unknown OS';

    // Detecting OS
    if ( navigator.appVersion.indexOf('Win') !== -1 ) {
      osName = 'Windows';
    }
    if ( navigator.appVersion.indexOf('Mac') !== -1 ) {
      osName = 'MacOS';
    }
    if ( navigator.appVersion.indexOf('X11') !== -1 ) {
      osName = 'UNIX';
    }
    if ( navigator.appVersion.indexOf('Linux') !== -1 ) {
      osName = 'Linux';
    }

    // In Opera, the true version is after "Opera" or after "Version"
    if ( nAgt.indexOf('Opera') !== -1 ) {

      verOffset = nAgt.indexOf('Opera');
      browserName = 'Opera';
      fullVersion = nAgt.substring(verOffset + 6);

      if ( nAgt.indexOf('Version') !== -1 ) {

        verOffset = nAgt.indexOf('Version')
        fullVersion = nAgt.substring(verOffset + 8);
      }
    } else if ( nAgt.indexOf('MSIE') !== -1 ) {

      verOffset = nAgt.indexOf('MSIE');
      browserName = 'Microsoft Internet Explorer';
      fullVersion = nAgt.substring(verOffset + 5);
    } else if ( nAgt.indexOf('Chrome') !== -1 ) {

      verOffset = nAgt.indexOf('Chrome');
      browserName = 'Chrome';
      fullVersion = nAgt.substring(verOffset + 7);
    } else if ( nAgt.indexOf('Safari') !== -1 ) {

      verOffset = nAgt.indexOf('Safari');
      browserName = 'Safari';
      fullVersion = nAgt.substring(verOffset + 7);

      if ( nAgt.indexOf('Version') !== -1 ) {

        verOffset = nAgt.indexOf('Version');
        fullVersion = nAgt.substring(verOffset + 8);
      }
    } else if ( nAgt.indexOf('Firefox') !== -1 ) {

      verOffset = nAgt.indexOf('Firefox');
      browserName = 'Firefox';
      fullVersion = nAgt.substring(verOffset + 8);
    } else if ( nAgt.lastIndexOf(' ') + 1 < nAgt.lastIndexOf('/') ) {

      nameOffset = nAgt.lastIndexOf(' ') + 1;
      verOffset = nAgt.lastIndexOf('/');
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);

      if ( browserName.toLowerCase() === browserName.toUpperCase() ) {
        browserName = navigator.appName;
      }
    }

    // trim the fullVersion string at semicolon/space if present
    if ( fullVersion.indexOf(';') !== -1 ) {

      ix = fullVersion.indexOf(';');
      fullVersion = fullVersion.substring(0, ix);
    }
    if ( fullVersion.indexOf(' ') !== -1 ) {

      ix = fullVersion.indexOf(' ');
      fullVersion = fullVersion.substring(0, ix);
    }

    majorVersion = parseInt('' + fullVersion, 10);

    if ( isNaN(majorVersion) ) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    return {
      browserName,
      fullVersion,
      majorVersion,
      appName: navigator.appName,
      userAgent: navigator.userAgent,
      os: osName,
      platform: 'pranadh_CLIENT'
    };
  }

  // Unsubscribe from SubscriptionPackage List
  // ----------------------------------------------------------------
  static unsubscribe(subscriptions: Subscription[]): void {

    if ( typeof subscriptions !== 'undefined' && subscriptions !== null && subscriptions.length > 0 ) {

      subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }

  // Capitalize String
  // ----------------------------------------------------------------
  static capitalizeString(str: string) {
    const pieces = str.split(' ');

    pieces.forEach((piece, index) => {
      pieces[ index ] = `${ piece.charAt(0).toUpperCase() }${ piece.substr(1) }`;
    });

    return pieces.join(' ');
  }

  // Update Bottom Reached State
  // ----------------------------------------------------------------
  updateBottomReachedState(status: boolean): void {

    this.bottomScrolled.next(status);
  }

  // Update Spinner Status
  // ----------------------------------------------------------------
  updateSpinnerState(status: boolean): void {

    this.appSpinner.next(status);
  }

  // Update Login Status
  // ----------------------------------------------------------------
  updateLoginState(state: boolean): void {

    this.loggedIn.next(state);
  }

  // Update User Details
  // ----------------------------------------------------------------
  updateUserDetails(user: CurrentUser): void {

    this.currentUser.next(user);
  }

  // Notification Center Success, Warning and Error Messages
  // ----------------------------------------------------------------
  showSuccessMessage(message: string, duration: number =2000): void {

    message = `<h4 class="font-weight-bold title m-0 >Awesome!</h4> <p class="mt-2">${ message }</p>`;

    // this.notyf_success.success({ message, duration });

    this.notyf_success.open({
      type: 'success',
      message,
      duration,
      
    });
  }

  showWarningMessage(message: string, duration: number = 2000): void {

    message = `<h4 class="font-weight-bold title m-0">Oops No!</h4> <p class="mt-2">${ message }</p>`;

    this.notyf.open({
      type: 'warning',
      message,
      duration
    });
  }

  showErrorMessage(message: string, duration: number = 2000): void {

    message = `<h4 class="font-weight-bold title m-0">Oh Snap!</h4> <p class="mt-2>${ message }</p>`;

    this.notyf.error({ 
      message, duration 
    });
  }

  // Open Confirmation PopUp [ Note: Will auto close after 10 seconds ] Will always return true on resolve
  // ----------------------------------------------------------------
  openConfirmationPopUp(message: string): Promise<boolean> {

    return new Promise( (resolve, reject) => {

      if ( typeof message === 'undefined' || message === null ) {
        reject('Message parameter is missing');
      }

      const subscription = this.matDialog.open(ConfirmationDialogComponent, {
        id: 'confirmation-dialog',
        data: { message },
        width: window.innerWidth > 768 ? '450px' : '95vw'
      }).afterClosed()
        .subscribe( (result) => {
          if ( typeof result === 'boolean' ) {
            result ? resolve(result) : reject('User cancelled operation');
          } else if ( typeof result === 'undefined' || result === 'cancel' ) {
            reject('User dismissed dialog');
          }

          subscription.unsubscribe();
        });
    });
  }
}
